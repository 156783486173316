import API from './API';

export default {
  getHowies() {
    return API().get('/api/v1/howy/', { withCredentials: true });
  },

  getHowy(slug) {
    return API().get(`/api/v1/howy/${slug}/`, { withCredentials: true });
  },

  markAsRead(slug) {
    return API().post(`/api/v1/howy/${slug}/mark_as_read`, {}, { withCredentials: true });
  },

  markAsUnread(slug) {
    return API().post(`/api/v1/howy/${slug}/mark_as_unread`, {}, { withCredentials: true });
  },

  giveWowy(slug) {
    return API().post(`/api/v1/howy/${slug}/give_wowy`, {}, { withCredentials: true });
  },

  retractWowy(slug) {
    return API().post(`/api/v1/howy/${slug}/retract_wowy`, {}, { withCredentials: true });
  }
};
