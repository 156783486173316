import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Categories from './Modules/Categories';
import Category from './Modules/Category';
import Howy from './Modules/Howy';
import User from './Modules/User';

const plugins = [
  createPersistedState({
    storage: window.sessionStorage,
  }),
];

const store = createStore({
  modules: {
    user: User,
    categories: Categories,
    category: Category,
    howy: Howy,
  },
  plugins,
});

export default store;
