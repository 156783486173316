<template>
  <Head :title="howy.title"/>

  <SidebarLayout>
    <div v-if="howy" class="howy">
      <h1>
        {{ howy.title }}
      </h1>

      <div v-dompurify-html="teaser" />

      <HowyButton
        v-if="howy.contents.length"
        :category-color="howy.category.color"
        @click="openModal"
        @keydown.left="carousel.prev()"
        @keydown.right="carousel.next()"
        @keydown.esc="escKeyHandler"
      >
        Weiterlesen
      </HowyButton>

      <div v-if="howy.contents.length">
        <div class="howy__meta">
          <div class="howy__meta-read">
              <p>
                {{
                  getTimeToRead(
                    howy.contents.map((content) => content.bodytext).join(' ')
                  )
                }}
              </p>

              <p class="howy__meta-progress">
                Dein Fortschritt: {{ getContentProgressLabel() }}

                <ProgressKeeper :index="getContentProgress()" :amount="howy.contents.length" />
              </p>
            </div>

            <div class="howy__meta-interaction">
              <p class="howy__meta-read-count">
                <b>{{ howy.read_count }}</b>
                {{ howy.read_count === 1 ? 'Homie hat' : 'Homies haben' }} das
                gelesen <span>🕶️</span>
              </p>

              <p class="howy__meta-wowy-count">
                <b>{{ howy.wowy_count }}</b>
                {{ howy.wowy_count === 1 ? 'Homie sagt' : 'Homies sagen' }}
                <b>wowy!</b>
                <span>👏</span>
              </p>
            </div>
        </div>

        <div class="howy__author">
          <p>
            Verfasser: {{ howy.author.first_name }} {{ howy.author.last_name }}
          </p>

          <p>
            Erstellungsdatum:
            {{ moment(String(howy.created_at)).format('DD.MM.YYYY') }}
          </p>

          <p>
            Zuletzt bearbeitet:
            {{ moment(String(howy.updated_at)).format('DD.MM.YYYY') }}
          </p>
        </div>
      </div>

      <Modal :show="showModal" :class="['howy__modal', animated && 'animated']" @close="closeAndCheckContents">
        <Carousel ref="carousel" :transition="0">
          <Slide v-for="index in howy.contents.length + 1" :key="index">
            <div class="carousel__item">
              <HowySheet
                :content="howy.contents[index - 1]"
                :category-color="howy.category.color"
                :contact="howy.author.first_name + ' ' + howy.author.last_name"
                :current-slide="index"
                :has-wowy="howy.has_wowy"
                :is-read="howy.is_read"
                @close="closeHowy"
                @clap="clapForHowy"
                @cancel="markContents"
              />
            </div>
          </Slide>

          <template #addons="{ slidesCount, currentSlide }">
            <Navigation v-if="slidesCount > 1" />

            <ProgressKeeper
              :index="currentSlide"
              :amount="slidesCount-1"
              :gradient-color="howy.category.color"
            />
          </template>
        </Carousel>
      </Modal>
    </div>
  </SidebarLayout>
</template>

<script setup>
import {Head} from "@inertiajs/vue3";
import {computed, ref, nextTick, onMounted} from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import SidebarLayout from '@/layouts/SidebarLayout.vue';
import Modal from '../components/ui/molecules/Modal.vue';
import HowyButton from '../components/ui/atoms/HowyButton.vue';
import HowySheet from '../components/ui/atoms/HowySheet.vue';
import ProgressKeeper from '../components/ui/atoms/ProgressKeeper.vue';
import HowyAPI from '@/services/HowyAPI';
import ContentAPI from '@/services/ContentAPI';
import getTimeToRead from '@/functions/getTimeToRead';
import { router } from '@inertiajs/vue3'

const store = useStore();

const props = defineProps(['slug', 'howy'])

import { usePage } from '@inertiajs/vue3'

//const props = defineProps(["categories"])

const page = usePage()
/* slug: {type: String,required: false}*/

const howy = computed(() => props.howy);
const categories = computed(() => page.props.sidebar.categories)
const user = computed(() => page.props.auth.user);
const teaser = computed(() => howy.value.teaser.replace(/<p>&nbsp;<\/p>/g, ''));

const carousel = ref(null);
const showModal = ref(false);
const animated = ref(false);

let drag = ref(false);
document.addEventListener('mousedown', () => drag.value = false);
document.addEventListener('mousemove', () => drag.value = true);

const escKeyHandler = () => {
  showModal.value = false;
  document.activeElement.blur();
};

const openModal = () => {
  showModal.value = true;
  document.body.classList.add('lock');
  let index = howy.value.contents.findIndex(content => content.is_read === false);

  if (index <= -1) {
    if (howy.value.is_read === true) {
      index = 0;
    } else index = howy.value.contents.length -1;
  }

  nextTick(() => carousel.value.slideTo(index));

  setTimeout(() => {
    animated.value = true
  }, 100);
}

const closeModal = () => {
  showModal.value = false;
  animated.value = false;
  document.body.classList.remove('lock');
  router.reload()
}

// Once user opens howy, set it as the active one
onMounted(() => {
  store.dispatch('howy/set', howy.value);
})

const closeAndCheckContents = () => {
  if (!drag.value) {
    markContents();
  }
}

const markContents = async () => {
  const currentSlide = (
    carousel.value && carousel.value.data.currentSlide.value < howy.value.contents.length
  ) ? (
    carousel.value.data.currentSlide.value
  ) : (
    howy.value.contents.length - 1
  );

  for (let index = 0; index <= currentSlide; index++) {
    if (!howy.value.contents[index].is_read) {
      try {
        const { status, data } = await ContentAPI.markAsRead(howy.value.contents[index].uuid);
        if (status === 201) {
          howy.value.contents[index].is_read = true;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  closeModal();
};

const resetContents = async () => {
  for (let index = 0; index < howy.value.contents.length; index++) {
    if (howy.value.contents[index].is_read) {
      try {
        const { status, data } = await ContentAPI.markAsUnread(howy.value.contents[index].uuid);
        if (status === 204) {
          howy.value.contents[index].is_read = false;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}

const getContentProgress = () => {
  if (howy.value.is_read) {
    return howy.value.contents.length;
  } else return howy.value.contents.filter(content => content.is_read).length;
};

const getContentProgressLabel = () => {
  if (howy.value.is_read) {
    return "100%";
  } else {
    const value = Math.round((100 * getContentProgress()) / howy.value.contents.length);
    return value === 100 ? "99%" : value + "%";
  }
}

const closeHowy = async () => {
  if (howy.value.is_read) {
    try {
      const { status, data } = await HowyAPI.markAsUnread(howy.value.slug);
      if (status === 204) {
        store.dispatch('howy/update', { is_read: false, read_count: howy.value.read_count - 1 });
        const category = categories.value.find(category => category.howies.find(cHowy => cHowy.slug === howy.value.slug));
        category.unread_count++;
        category.howies.find(cHowy => cHowy.slug === howy.value.slug).is_read = false;
        store.dispatch('categories/set', categories);
        //if(user.value.dashboard.read_howies > 0) user.value.dashboard.read_howies--;
        //store.dispatch('user/set', user);

        resetContents();
      }
    } catch (error) {
      console.log(error);
    }
  } else {
    try {
      const { status, data } = await HowyAPI.markAsRead(howy.value.slug);
      if (status === 201) {
        store.dispatch('howy/update', { is_read: true, read_count: howy.value.read_count + 1 });
        const category = categories.value.find(category => category.howies.find(cHowy => cHowy.slug === howy.value.slug));
        category.unread_count--;
        category.howies.find(cHowy => cHowy.slug === howy.value.slug).is_read = true;
        store.dispatch('categories/set', categories);
        //user.value.dashboard.read_howies++;
        //store.dispatch('user/set', user);

        markContents();
      }
    } catch (error) {
      console.log(error);
    }
  }

  closeModal();
};

const clapForHowy = async () => {
  if (howy.value.has_wowy) {
    try {
      const { status, data } = await HowyAPI.retractWowy(howy.value.slug);
      if (status === 201) {
        store.dispatch('howy/update', { has_wowy: false, wowy_count: howy.value.wowy_count - 1 });
        if(user.value.dashboard.given_wowies > 0) user.value.dashboard.given_wowies--;
        store.dispatch('user/set', user);
      }
    } catch (error) {
      console.log(error);
    }
  } else {
    try {
      const { status, data } = await HowyAPI.giveWowy(howy.value.slug);
      if (status === 201) {
        store.dispatch('howy/update', { has_wowy: true, wowy_count: howy.value.wowy_count + 1 });
        user.value.dashboard.given_wowies++;
        store.dispatch('user/set', user);
      }
    } catch (error) {
      console.log(error);
    }

    if (!howy.value.is_read) {
      closeHowy();
    }
  }

  closeModal();
};
</script>

<style lang="sass">
.howy
  margin: 0 var(--page-spacing)
  @media only screen and (max-width: 667px)
    margin: 0 var(--page-spacing-mobile)

  h1
    @media only screen and (max-width: 1280px)
      font-size: 48px
    @media only screen and (max-width: 480px)
      font-size: 40px

  &__author
    line-height: 40px
    margin-bottom: 44px
    @media only screen and (max-width: 480px)
      line-height: 28px

  &__meta
    display: flex
    justify-content: space-between
    line-height: 40px
    margin-bottom: 44px
    white-space: pre
    @media only screen and (max-width: 1280px)
      display: block
    @media only screen and (max-width: 480px)
      line-height: 28px
      margin-bottom: 24px

    &-interaction, &-read
      margin-bottom: 44px
      @media only screen and (max-width: 480px)
        margin-bottom: 24px

    &-interaction
      text-align: right
      @media only screen and (max-width: 1280px)
        text-align: left
        width: max-content

    &-progress
      align-items: center
      display: flex
      white-space: nowrap

      .progress-keeper
        box-shadow: var(--glow-and-shadow)
        margin-left: 16px
        width: 150px

    &-wowy-count, &-read-count
      padding-right: 36px
      position: relative
      @media only screen and (max-width: 1280px)
        padding-left: 36px

      span
        position: absolute
        font-size: 24px
        right: 0
        @media only screen and (max-width: 1280px)
          left: 0

.howy__modal
  .progress-keeper
    background: rgba(white, .1)
    margin: 0 auto
    width: calc(var(--modal-width) - var(--modal-margin))
    @media only screen and (max-width: 1280px)
      max-width: calc(var(--modal-width) - var(--modal-margin))
      width: calc(100% - 80px)
    @media only screen and (max-width: 667px)
      bottom: 90px
      left: 40px
      position: fixed
      right: 40px

  &.animated
    .carousel__track
      transition-duration: 0.8s !important
      transition-delay: 0.5s !important

    .progress-keeper__bar
      transition-duration: 0.3s

  .carousel
    pointer-events: none
    max-width: 1200px
    width: 100%

    &__viewport
      overflow: visible

    &__item
      pointer-events: auto

    &__prev,
    &__next
      background-color: var(--color-bg)
      color: var(--color-text)
      height: 48px
      inset: auto
      left: 50%
      margin-top: calc( var(--modal-margin) / 3 )
      pointer-events: auto
      width: 48px
      @media only screen and (max-width: 667px)
        bottom: 24px
        position: fixed

      svg
        font-size: 32px

    &__prev
      transform: translate(calc(-100% - 14px), 0)

    &__next
      transform: translate(14px, 0)

    &__prev--in-active,
    &__next--in-active
      cursor: default
      opacity: 0.3

    .carousel__slide--visible
      transform: rotateY(0)
</style>
