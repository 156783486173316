<template>
  <div v-if="content" class="howy__sheet-container">
    <div
      class="howy__close"
      @click.self="$emit('cancel')"
    >
      <CloseIcon />
    </div>

    <div class="howy__sheet">
      <h2>{{ content.title }}</h2>

      <div v-dompurify-html="howyText" />
    </div>

    <div v-if="currentSlide === 1" class="howy__greet">
      <img src="/resources/assets/howy-solo@2x.png" />
    </div>
  </div>

  <div v-else class="howy__sheet-container">
    <div class="howy__done">
      <div class="howy__done-img-wrapper">
        <img src="/resources/assets/howy-solo@2x.png" />
      </div>

      <div class="howy__done-content">
        <h2>Geschafft!</h2>

        <p>Spüre, wie howys Wisdom Deine Adern flutet!</p>

        <p>
          <strong>Noch Fragen?</strong> <br />
          Dein:e Ansprechpartner:in zu dem Thema ist: {{ contact }}
        </p>

        <div class="howy__done-buttons">
          <Button
            :category-color="categoryColor"
            @click.self="$emit('close')"
          >
            {{ isRead ? "Howy zurücksetzen" : "Howy abschließen" }}
          </Button>

          <Button
            ref="clapButton"
            :category-color="categoryColor"
            @click.self="clap"
          >
            {{ hasWowy ? "Wowy zurücknehmen" : "👏 Wowy geben & abschließen" }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Button from './Button.vue';
import CloseIcon from '@/components/ui/icons/CloseIcon.vue';
import { emojisplosion } from 'emojisplosion';

const props = defineProps({
  content: {
    type: Object,
  },
  categoryColor: {
    type: String,
    required: true,
  },
  contact: {
    type: String,
    required: true,
  },
  currentSlide: {
    type: Number,
    required: true,
  },
  hasWowy: {
    type: Boolean,
    required: true,
  },
  isRead: {
    type: Boolean,
    required: true,
  }
});

const emit = defineEmits(['close', 'clap', 'cancel']);

const clapButton = ref(null);
const howyText = ref(props.content && props.content.bodytext.replace(/<p>&nbsp;<\/p>/g, ''));

const clap = () => {
  if (!props.hasWowy) {
    emojisplosion({
      position() {
        const offset = cumulativeOffset(clapButton.value.$el);

        return {
          x: offset.left + clapButton.value.$el.clientWidth / 2,
          y: offset.top + clapButton.value.$el.clientHeight / 2,
        };
      },
      emojis: ['👏'],
      physics: {
        fontSize: { min: 32, max: 48 },
      },
    });
  }

  emit('clap');
};

const cumulativeOffset = (element) => {
  const rect = element.getBoundingClientRect();
  let top = 0;

  do {
    top += element.offsetTop || 0;
    element = element.offsetParent;
  } while (element);

  return {
    top: top,
    left: rect.left + window.scrollX,
  };
};
</script>

<style lang="sass">
.howy__sheet-container
  border-radius: var(--modal-border-radius)
  display: flex
  margin-bottom: calc(var(--modal-margin) / 2)
  max-height: calc(100vh - 210px)
  perspective: 1000px
  perspective-origin: 50% 50%
  @media only screen and (max-width: 667px)
    border-radius: var(--modal-border-radius-mobile)
    margin-bottom: 16px

.howy__sheet
  background-color: var(--color-bg)
  border-radius: var(--modal-border-radius)
  display: flex
  flex-direction: column
  max-width: var(--modal-width)
  padding: calc(var(--modal-margin) / 2) var(--modal-margin) calc(var(--modal-margin) * 0.66) var(--modal-margin)
  text-align: left
  width: 100%
  @media only screen and (max-width: 1180px)
    padding: calc(var(--modal-margin) / 2) calc(var(--modal-margin) / 2) calc(var(--modal-margin) * 0.66) calc(var(--modal-margin) / 2)
  @media only screen and (max-width: 667px)
    border-radius: var(--modal-border-radius-mobile)
    margin: 16px
    max-width: calc(var(--modal-width) * 0.75)
    padding: 24px

  h2
    font-size: 29px
    line-height: 1.5
    margin-bottom: 24px
    @media only screen and (max-width: 667px)
      font-size: 16px
      margin-bottom: 12px

  > div
    overflow: auto

  li
    font-size: 18px
    margin-left: 20px
    @media only screen and (max-width: 667px)
      font-size: 14px

  a
    text-decoration: underline

.howy__done
  align-items: center
  background-color: var(--color-bg)
  border-radius: var(--modal-border-radius)
  display: flex
  text-align: center
  @media only screen and (max-width: 667px)
    border-radius: var(--modal-border-radius-mobile)
    margin: 16px

  h2
    font-size: 47px
    text-transform: uppercase
    transition: 0.5s
    transition-delay: 1.1s
    @media only screen and (max-width: 667px)
      font-size: 24px

  p
    margin-bottom: 16px
    transition: 0.5s
    transition-delay: 1.2s

.howy__done-img-wrapper
  overflow: hidden
  transition: width 0.5s
  transition-delay: 1.1s
  width: calc(200px + var(--modal-margin))
  @media only screen and (max-width: 930px)
    display: none

  img
    margin-left: var(--modal-margin)
    transform-origin: center right
    transition: transform 0.6s cubic-bezier(0.77,0,0.18,1), opacity 0.6s
    transition-delay: 1.4s
    width: 200px

.howy__done-content
  padding: calc(var(--modal-margin) / 2) var(--modal-margin) calc(var(--modal-margin) * 0.66) var(--modal-margin)
  width: var(--modal-width)
  @media only screen and (max-width: 667px)
    padding: 24px
    width: 100%

.howy__done-buttons
  display: flex
  flex-direction: column
  margin: 0 auto
  max-width: 350px
  @media only screen and (max-width: 667px)
    max-width: 290px

  button
    transition: transform 0.5s, opacity 0.5s
    transition-delay: 1.4s

  button:first-child
    transition-delay: 1.3s

.howy__greet
  align-items: center
  background-color: #5d48ff
  border-radius: 0 var(--modal-border-radius) var(--modal-border-radius) 0
  display: flex
  overflow: hidden
  position: relative
  text-align: left
  transform: translateX(-50px)
  transition: width 0.5s
  transition-delay: 1.1s
  width: 500px
  z-index: -1
 
  @media only screen and (max-width: 1280px)
    width: 400px
 
  @media only screen and (max-width: 980px)
      display: none

  img
    position: absolute
    margin-left: 50px
    right: 105px
    width: 240px
    
    @media only screen and (max-width: 1280px)
      right: 55px

  &::after
    background-color: white
    border-radius: 50%
    bottom: -400px
    content: ""
    height: 800px
    right: -400px
    opacity: 0.1
    position: absolute
    width: 800px
    z-index: -1

.howy__close
  align-items: center
  background: white
  border-radius: 50%
  cursor: pointer
  display: flex
  height: 38px
  justify-content: center
  position: absolute
  right: -24px
  top: -24px
  transition: opacity 400ms
  transition-delay: 1.3s
  width: 38px
  @media only screen and (max-width: 667px)
    height: 30px
    right: 8px
    top: -21px
    width: 30px

  svg
    pointer-events: none
    @media only screen and (max-width: 667px)
      width: 12px

.howy__modal
  .howy__sheet, .howy__sheet > *, .howy__done
    transition: 0.8s

  .carousel__slide--next
    .howy__sheet,
    .howy__done
      opacity: 0
      transform: rotateY(90deg) scale(0)
      transition-delay: 0.5s

      // h2
      //   opacity: 0
      //   transform: translateX(30px)
      //   transition-delay: 0.1s
      //   @media only screen and (max-width: 667px)
      //     transform: translateX(10px)

    .howy__done-img-wrapper
      transition-delay: 0.3s
      width: 0

      img
        transform: scale(0)
        transition-delay: 0s
        opacity: 0

    .howy__done-content p
      opacity: 0
      transform: translateY(50px)
      transition-delay: 0.2s
      @media only screen and (max-width: 667px)
        transform: translateY(30px)

    .howy__done-buttons
      button
        opacity: 0
        transform: translateY(50px)
        transition-delay: 0s
        @media only screen and (max-width: 667px)
          transform: translateY(30px)

      button:first-child
        transition-delay: 0.1s

    .howy__close
      opacity: 0
      transition-delay: 0s

  .carousel__slide--prev
    .howy__sheet
      opacity: 0
      transform: rotateY(-90deg) scale(0)

      // h2
      //   opacity: 0
      //   transform: translateX(-30px)
      //   transition-delay: 0.1s
      //   @media only screen and (max-width: 667px)
      //     transform: translateX(-10px)

    .howy__greet
      transition-delay: 0.1s
      width: 0
    
    .howy__close
      opacity: 0
      transition-delay: 0s

  .carousel__slide--active
    .howy__sheet
      transition-delay: 0.5s

      & > div
        //transition-delay: 0.8s

    .howy__done
      transition-delay: 0.5s

  .carousel__slide--next .howy__sheet,
  .carousel__slide--prev .howy__sheet
    transition-delay: 0.5s

    // & > div
    //   transform: translateY(50px)
    //   opacity: 0
    //   @media only screen and (max-width: 667px)
    //     transform: translateY(30px)

  &.modal-enter-from,
  &.modal-leave-to
    .howy__sheet,
    .howy__done
      transform: scale(0)
      transform-origin: bottom center

    .howy__greet
      transition-delay: 0s
      width: 0

    .howy__close
      opacity: 0
      transition-delay: 0s

  &.modal-enter-from .howy__sheet
    h2
      opacity: 0
      transform: translateX(-30px)
      @media only screen and (max-width: 667px)
        transform: translateX(-10px)

    & > div
      opacity: 0
      transform: translateY(50px)
      @media only screen and (max-width: 667px)
        transform: translateY(30px)

  &.modal-leave-to
    transition-delay: 0.6s

    .howy__sheet
      transition-delay: 0.5s

      h2
        opacity: 0
        transform: translateX(-30px)
        transition-delay: 0.3s
        @media only screen and (max-width: 667px)
          transform: translateX(-10px)

      & > div
        opacity: 0
        transform: translateY(50px)
        transition-delay: 0s
        @media only screen and (max-width: 667px)
          transform: translateY(30px)

    .howy__done
      transition-delay: 0.5s

    .howy__done-img-wrapper
      transition-delay: 0.3s
      width: 0

      img
        transform: scale(0)
        transition-delay: 0s
        opacity: 0

    .howy__done-buttons
      button
        opacity: 0
        transform: translateY(50px)
        transition-delay: 0s
        @media only screen and (max-width: 667px)
          transform: translateY(30px)

      button:first-child
        transition-delay: 0.1s

  &.modal-enter-active
    .howy__sheet
      transition-delay: 0s

    .howy__greet
      transition-delay: 0.6s

    .howy__sheet h2
      transition-delay: 0.4s

    .howy__sheet > div
      transition-delay: 0.6s
</style>
