<template>
  <div
    :class="['backdrop', show && 'backdrop--visible']"
    @click="show && (show = false)"
  ></div>
  <div class="menu">
    <button
      :class="['avatar', show && 'avatar--active']"
      :style="`background-image: url(${
        user && user.picture_url ? user.picture_url : PartyDogAvatar
      })`"
      @click="show = !show"
    ></button>
    <Transition name="popup">
      <div v-if="user && show" class="popup">
        <span class="popup__name">{{ user.first_name }}</span>

        <Link class="popup__link" href="/logout" method="post" as="button" type="button">
        <!--<a class="popup__link" @click="logout">-->
          <span>Logout</span>
          <LogoutIcon />
        <!--</a>-->
        </Link>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import LogoutIcon from '@/components/ui/icons/LogoutIcon.vue';
import PartyDogAvatar from '@/../assets/party-dog.png'
import {Link, usePage} from "@inertiajs/vue3";

const page = usePage()

const show = ref(false)
const user = page.props.auth.user
</script>

<style lang="sass" scoped>
.backdrop
    inset: 0
    pointer-events: none
    position: fixed
    transition: all 0.3s ease
    z-index: 5

    &--visible
        backdrop-filter: blur(1.8px)
        background-color: transparentize(#eff0f4, 0.2)
        pointer-events: all

.menu
    align-items: flex-end
    display: flex
    flex-direction: column
    gap: 16px
    position: fixed
    right: 28px
    top: 22px
    z-index: 10

    @media only screen and (max-width: 1024px)
      right: 14px
      top: 14px

    .avatar
        background-repeat: no-repeat
        background-size: contain
        border: 2px solid var(--color-text)
        border-radius: 9999px
        cursor: pointer
        height: 68px
        width: 68px

        @media only screen and (max-width: 1024px)
          border: 0
          height: 40px
          pointer-events: none
          width: 40px

        &:hover
            border-color: #8e77ff

        &--active, &--active:hover
            border-color: #5d48ff

    .popup
        background-color: var(--purple-dark)
        border-radius: 15px
        color: var(--color-bg)
        display: flex
        flex-direction: column
        font-size: 16px
        gap: 2px
        padding: 6px 10px
        transition: all 0.2s ease

        &__name
          font-family: Brevia, sans-serif
          font-size: 18px
          font-weight: 700
          line-height: 28px
          text-transform: uppercase

        &__link
            align-items: center
            cursor: pointer
            display: flex
            gap: 8px

            svg
                font-size: 18px
                stroke-width: 2px

.popup-enter-from, .popup-leave-to
    opacity: 0
    transform: scale(0.8)
</style>
