export default {
  namespaced: true,

  state: {
    categories: null,
  },

  getters: {
    get: (state) => state.categories,
  },

  mutations: {
    SET(state, newValue) {
      state.categories = newValue;
    },
    CLEAR(state) {
      state.categories = null;
    },
  },

  actions: {
    set({ commit }, newValue) {
      commit('SET', newValue);
    },
    clear({ commit }) {
      commit('CLEAR');
    },
  },
};
