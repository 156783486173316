import './bootstrap';
import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import Layout from './Layout.vue'
import store from '@/store';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import './index.sass'

createInertiaApp({
    title: title => `${title} - Howy`,
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
        let page = pages[`./Pages/${name}.vue`]
        page.default.layout = page.default.layout || Layout
        return page
    },
    setup({ el, App, props, plugin }) {
        createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(store)
            .use(VueDOMPurifyHTML)
            .mount(el)
    },
})
