<template>
    <Head title="Home"/>
    <SidebarLayout :categories="categories">
        <div class="home">
            <div class="home__text-wrapper">
                <h1>Hier wird das How zum Wow!</h1>

                <h3>Immer schön neugierig bleiben:</h3>

                <p>
                    Bei appmotion gibt es keine Information, die es nicht gibt. Und hier
                    findest Du sie alle – übersichtlich, kurz &amp; knapp. Aufschlauen war
                    nie einfacher!
                </p>
            </div>

            <!-- double slides to make wrap around available -->
            <div class="card-container">
                <Carousel :settings="settings" :breakpoints="breakpoints">
                    <Slide :key="1">
                        <StatsCard
                            :emoji="'🥳'"
                            :title="getPercentage(dashboard.read_howies,dashboard.public_howies) + '%'"
                            :text="'Aller Howys gelesen'"
                        />
                    </Slide>

                    <Slide :key="2">
                        <StatsCard
                            :emoji="'👏'"
                            :title="dashboard.given_wowies"
                            :text="dashboard.given_wowies === 1
                                      ? 'Gegebenes Wowy'
                                      : 'Gegebene Wowys'"
                        />
                    </Slide>

                    <Slide :key="3">
                        <StatsCard
                            :emoji="'🤓'"
                            :title="dashboard.unread_howies"
                            :text="dashboard.unread_howies === 1
                                      ? 'Howy verfügbar'
                                      : 'Howys verfügbar'"
                        />
                    </Slide>

                    <Slide :key="4">
                        <StatsCard
                            :emoji="'🥳'"
                            :title="getPercentage(dashboard.read_howies,dashboard.public_howies) + '%'"
                            :text="'Aller Howys gelesen'"
                        />
                    </Slide>

                    <Slide :key="5">
                        <StatsCard
                            :emoji="'👏'"
                            :title="dashboard.given_wowies"
                            :text="dashboard.given_wowies === 1
                                      ? 'Gegebenes Wowy'
                                      : 'Gegebene Wowys'"
                        />
                    </Slide>

                    <Slide :key="6">
                        <StatsCard
                            :emoji="'🤓'"
                            :title="dashboard.unread_howies"
                            :text="dashboard.unread_howies === 1
                                      ? 'Howy verfügbar'
                                      : 'Howys verfügbar'"
                        />
                    </Slide>
                </Carousel>
            </div>

            <div class="home__text-wrapper">
                <h2>Schon gelesen?</h2>

                <h3>Wie schön, dass du so neugierig bist.</h3>
            </div>

            <div class="card-container">
                <Carousel :settings="settings" :breakpoints="breakpoints">
                    <Slide :key="1">
                        <HowyCard
                            :emoji="'🕵️‍♀️'"
                            :howy="dashboard.newest_howy"
                            :title="'Neuester Howy'"
                        />
                    </Slide>

                    <Slide :key="2">
                        <HowyCard
                            :emoji="'👏'"
                            :howy="dashboard.most_wowies"
                            :title="'Meistgewowt'"
                        />
                    </Slide>

                    <Slide :key="3">
                        <HowyCard
                            :emoji="'🤓'"
                            :howy="dashboard.most_read"
                            :title="'Meistgelesen'"
                        />
                    </Slide>

                    <Slide :key="4">
                        <HowyCard
                            :emoji="'🕵️‍♀️'"
                            :howy="dashboard.newest_howy"
                            :title="'Neuester Howy'"
                        />
                    </Slide>

                    <Slide :key="5">
                        <HowyCard
                            :emoji="'👏'"
                            :howy="dashboard.most_wowies"
                            :title="'Meistgewowt'"
                        />
                    </Slide>

                    <Slide :key="6">
                        <HowyCard
                            :emoji="'🤓'"
                            :howy="dashboard.most_read"
                            :title="'Meistgelesen'"
                        />
                    </Slide>
                </Carousel>
            </div>
        </div>
    </SidebarLayout>
</template>

<script setup>
import {Head} from "@inertiajs/vue3";
import {Carousel, Navigation, Slide} from 'vue3-carousel';
import SidebarLayout from '@/layouts/SidebarLayout.vue';
import HowyCard from '@/components/ui/atoms/HowyCard.vue';
import StatsCard from '@/components/ui/atoms/StatsCard.vue';
import getPercentage from "@/functions/getPercentage.js";
import { usePage } from '@inertiajs/vue3'


// Receive user-object from inertia-controller
const props = defineProps(["dashboard"])
const dashboard = props.dashboard
console.log(dashboard)
const page = usePage()

const settings = {
    itemsToShow: 1.5,
    snapAlign: 'center',
    wrapAround: true,
};

const breakpoints = {
    481: {
        itemsToShow: 2,
    },
    740: {
        itemsToShow: 2.5,
    },
    1361: {
        itemsToShow: 3,
        snapAlign: 'start',
        mouseDrag: false,
        touchDrag: false,
    }
};
</script>

<style lang="sass">
.home
    .card-container
        margin: 60px calc(var(--page-spacing) - 16px)
        max-width: 1024px
        padding: 16px 0
        @media only screen and (max-width: 1360px)
            margin: 60px 0
        @media only screen and (max-width: 667px)
            margin: 16px 0

    &__text-wrapper
        margin: 0 var(--page-spacing)

        @media only screen and (max-width: 667px)
            margin: 0 var(--page-spacing-mobile)

    .carousel__slide--next .howy-card,
    .carousel__slide--next .stats-card,
    .carousel__slide--prev .howy-card,
    .carousel__slide--prev .stats-card
        @media only screen and (max-width: 1360px)
            filter: blur(1px)

    .carousel__slide--active .howy-card,
    .carousel__slide--active .stats-card
        @media only screen and (max-width: 1360px)
            transform: scale(1.1)
</style>
