export default {
  namespaced: true,

  state: {
    user: null,
  },

  getters: {
    get: (state) => state.user,
  },

  mutations: {
    SET(state, newValue) {
      state.user = newValue;
    },
    CLEAR(state) {
      state.user = null;
    },
  },

  actions: {
    set({ commit }, newValue) {
      commit('SET', newValue);
    },
    clear({ commit }) {
      commit('CLEAR');
    },
  },
};
