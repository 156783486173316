import API from './API';

export default {
  markAsRead(uuid) {
    return API().post(`/api/v1/card/${uuid}/mark_as_read`, {}, { withCredentials: true });
  },

  markAsUnread(uuid) {
    return API().post(`/api/v1/card/${uuid}/mark_as_unread`, {}, { withCredentials: true });
  }
};
