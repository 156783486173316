<template>
    <li
        :class="['category', (isCategorySelected() || evaluateIfIndicatorIsShown(category)) && 'category--active']"
    >
        <!-- Unread howies in category tag -->
        <div v-show="category.unread_count" class="category__unread">
          <span class="category__unread--count">{{ category.unread_count }}</span>
        </div>

        <div v-show="0" class="category__unread">
            <span class="category__unread--count">{{ 0 }}</span>
        </div>

        <a class="category__link" @click="toggleCategory(category)">
            <!-- Category title -->
            <span class="category__link-title">{{ category.title }}</span>

            <!-- Category chevron -->
            <span>
        <svg
            class="category__link-chevron"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              v-if="isCategorySelected()"
              d="M19 9L12 16L5 9"
              stroke="currentColor"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
          ></path>
          <path
              v-else
              d="M9 5L16 12L9 19"
              stroke="currentColor"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
          ></path>
        </svg>
      </span>
        </a>

        <!-- List of howies in category -->
        <Collapsible>
            <ul v-show="isCategorySelected()" class="category__list">
                <CategoryItem
                    v-for="howy in category.howies"
                    :key="howy.slug"
                    :howy="howy"
                    :color="category.color"
                    @click="$emit('closeSidebar')"
                />
            </ul>
        </Collapsible>
    </li>
</template>

<script setup>
import {computed} from 'vue';
import {useStore} from 'vuex';
import CategoryItem from './CategoryItem.vue';
import Collapsible from '../../ui/atoms/Collapsible.vue';
import routes from "../../../router/routes.js";

const store = useStore();
const selectedCategory = computed(() => store.getters['category/get']);
const howyInFocus = computed(() => store.getters['howy/get']);

const props = defineProps({
    category: {
        type: Object,
        required: true,
    },
    primaryColor: {
        type: String,
        required: true,
    },
    secondaryColor: {
        type: String,
        required: true,
    },
});
const toggleCategory = (category) => {
    isCategorySelected()
        ? store.dispatch('category/clear')
        : store.dispatch('category/set', category);
};

const evaluateIfIndicatorIsShown = (category) => {
    if (!howyInFocus.value) return;
    if (
        howyInFocus.value.category.title === category.title &&
        window.location.pathname.startsWith(routes.howy) //meh solution, but good enough for now
    )
        return true;
};

const isCategorySelected = () => {
  return !!(selectedCategory.value && selectedCategory.value.slug === props.category.slug)
}

const emit = defineEmits(['closeSidebar']);
</script>

<style lang="sass">
.category
    position: relative

    &__unread
        background-color: transparentize(#eff0f4, 0.7)
        border-radius: 9999px
        height: 20px
        left: 24px
        position: absolute
        top: 22px
        width: 34px

        &--count
            color: var(--color-bg)
            font-size: 12px
            font-weight: 700
            left: 50%
            line-height: 1
            position: absolute
            top: 50%
            transform: translate(-50%, -50%)

    &__link
        align-items: center
        color: var(--color-bg)
        cursor: pointer
        display: flex
        justify-content: space-between
        padding: 16px 24px 16px 80px

        &-title
            font-family: Brevia, sans-serif
            font-size: 18px
            font-weight: 700
            line-height: 28px
            text-transform: uppercase

        &-chevron
            height: 16px
            width: 16px

    &__list
        overflow: hidden

    &::after
        background-image: linear-gradient(180deg, v-bind(primaryColor) 30%, v-bind(secondaryColor))
        content: ''
        height: 100%
        opacity: 0
        position: absolute
        top: 0
        transition: opacity 500ms
        width: 100%
        z-index: -1

    &--active::after
        opacity: 1
        transition: opacity 200ms
</style>
