export default {
  namespaced: true,

  state: {
    howy: null,
  },

  getters: {
    get: (state) => state.howy,
  },

  mutations: {
    SET(state, newValue) {
      state.howy = newValue;
    },
    CLEAR(state) {
      state.howy = null;
    },
    UPDATE(state, obj) {
      state.howy = {...state.howy, ...obj}
    },
  },

  actions: {
    set({ commit }, newValue) {
      commit('SET', newValue);
    },
    clear({ commit }) {
      commit('CLEAR');
    },
    update({ commit }, newValue) {
      commit('UPDATE', newValue);
    },
  },
};
