<template>
  <Head title="Login"/>
  <div class="background">
    <div class="login">
      <div class="login__top">
        <img class="logo" src="/resources/assets/howy-logo@2x.png"/>
      </div>
      <div class="login__content">
        <div v-if="loginError" class="login__error">
          <div>
            <h4>Login fehlerhaft!</h4>
            <ul>
              <li v-for="error in Object.values(loginError)">
                {{error}}
              </li>
            </ul>
          </div>
        </div>
        <div class="login__middle">
          <form class="form" @submit.prevent="submit">
            <label class="label" for="email">E-Mail</label>
            <input class="input" type="email" id="email" name="email" v-model="form.email">
            <label class="label" for="password">Passwort</label>
            <input class="input" type="password" id="password" name="password" v-model="form.password">
            <button class="button" type="submit" :disabled="form.processing">Login</button>
          </form>
        </div>
      </div>
      <div class="login__bottom">
        <button class="button" @click="openGoogleLoginPage()">
          <GoogleIcon/>
          Login mit Google
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Head } from "@inertiajs/vue3";
import {computed, ref} from 'vue';
import GoogleIcon from '@/components/ui/icons/GoogleIcon.vue';
import {useForm} from "@inertiajs/vue3";
import routes from "../router/routes.js";
import { usePage } from '@inertiajs/vue3'

const page = usePage()

const form = useForm({
  email: null,
  password: null,
  remember: false,
})
const loginError = ref();

function submit() {
  loginError.value = undefined

  form.post('/login', {
    onSuccess: () => window.location = routes.home,
    onError: (errors) => loginError.value = errors,
  })
}

const user = computed(() => page.props.auth?.user);

if (user.value) {
  window.location = routes.home
}

const openGoogleLoginPage = () => {
  const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
  const redirectUri = 'api/v1/auth/login/google';

  const scope = [
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
  ].join(' ');

  const params = {
    response_type: 'code',
    client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
    redirect_uri: `${import.meta.env.VITE_API_URL}${redirectUri}`,
    prompt: 'select_account',
    access_type: 'offline',
    scope,
  };

  const urlParams = new URLSearchParams(params).toString();
  window.location = `${googleAuthUrl}?${urlParams}`;
};
</script>

<style lang="sass">
.background
  align-items: center
  background-color: var(--color-bg)
  display: flex
  justify-content: center
  width: 100%

  .login
    border-radius: var(--card-border-radius)
    box-shadow: -2px -2px 7px 0 #fff, -1px -1px 1px 0 #fff, 9px 6px 10px -8px #bcbdc1
    margin: 0 12px
    width: var(--modal-width)

    &__top, &__bottom, &__middle, &__error
      display: flex
      justify-content: center
      width: 100%

    &__error
      margin-bottom: 10px
      ul
        padding-left: 10px
        list-style-type: '- '

    &__content
      padding-top: var(--modal-margin)

    &__top
      background: linear-gradient(170deg, var(--purple-dark), var(--purple-light))
      border-radius: var(--card-border-radius) var(--card-border-radius) 0 0
      padding-bottom: calc(var(--modal-margin) / 2)
      padding-top: var(--modal-margin)

    &__bottom
      background-color: var(--color-bg)
      border-radius: 0 0 var(--card-border-radius) var(--card-border-radius)
      padding: calc(var(--modal-margin) / 2) 0

    .logo
      height: 96px
      user-select: none

  .button
    align-items: center
    background-color: var(--purple-dark)
    border-radius: var(--card-border-radius)
    color: white
    cursor: pointer
    display: flex
    font-size: 16px
    font-weight: 700
    gap: 8px
    padding: 8px 16px
    transition: all 0.2s

    &:hover
      background-color: #8e77ff
      transform: scale(1.03)

    &:active
      background-color: #42afff

  .input
    align-items: center
    background-color: var(--color-bg)
    border-color: var(--purple-dark)
    border-radius: var(--card-border-radius)
    color: var(--color-text)
    display: flex
    font-size: 16px
    //font-weight: 300
    min-width: 300px
    gap: 8px
    padding: 8px 16px
    transition: all 0.2s

    &:hover
      transform: scale(1.03)

  .label
    color: var(--color-text)
    font-size: 16px
    font-weight: 700
    padding: 8px 16px
    font-family: Brevia, sans-serif

  .form
    display: flex
    flex-direction: column
    align-items: flex-start

</style>
