export default function (text, altTextOrWpm, wpm) {
  if (typeof text !== 'string') {
    throw new TypeError(
      `Expected an String in the first argument, got ${typeof text}`
    );
  }

  if (altTextOrWpm !== undefined) {
    if (typeof altTextOrWpm !== 'string' && typeof altTextOrWpm !== 'number') {
      throw new TypeError(
        `Expected an String/Number in the second argument, got ${typeof altTextOrWpm}`
      );
    }
  }

  if (wpm !== undefined && typeof wpm !== 'number') {
    throw new TypeError(
      `Expected a Number in the third argument, got ${typeof wpm}`
    );
  }

  let wordsPerMin = 220; // NOTE: Defines the reading speed in words per minute

  if (typeof altTextOrWpm === 'number') {
    wordsPerMin = altTextOrWpm;
  }

  if (wpm !== undefined && typeof wpm === 'number') {
    wordsPerMin = wpm;
  }

  const timeTaken = Math.round(text.split(' ').length / wordsPerMin);
  const minutesString = !timeTaken || timeTaken === 1 ? 'Minute' : 'Minuten';
  return `Durchschnittliche Lesedauer: ${
    timeTaken || 'weniger als 1'
  } ${minutesString}`;
}
