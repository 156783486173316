<template>
    <div class="mobile-header">
        <div
            :class="['mobile-header__button', showSidebar && 'mobile-header__button--active']"
            @click="showSidebar = !showSidebar"
        >
            <div class="mobile-header__button-line btl-1"></div>
            <div class="mobile-header__button-line btl-2"></div>
            <div class="mobile-header__button-line btl-3"></div>
        </div>

        <Link :href="'/'">
            <img src="/resources/assets/howy-logo@2x.png" />
        </Link>
    </div>

    <div :class="['sidebar', showSidebar && 'sidebar--visible']">
        <!-- Logo -->
        <div class="sidebar__logo">
            <Link href="/" @click="resetSelectedCategory">
                <img src="/resources/assets/howy-logo@2x.png" />
            </Link>
        </div>

        <!-- Loading spinner  -->
        <div v-if="loading" class="sidebar__loading">
            <div class="sidebar__loading--spinner"></div>
        </div>

        <!-- Nav with list of categories -->
        <nav v-else class="sidebar__category-list">
            <ul>
                <Category
                    v-for="category in categories"
                    :key="category.uuid"
                    :category="category"
                    :primary-color="category.color.substring(0,7)+'CC'"
                    :secondary-color="category.color.substring(0,7)+'33'"
                    @close-sidebar="showSidebar = false"
                />
            </ul>
        </nav>

        <div class="sidebar__logout-button">
            <Link href="/logout" method="post" as="button" type="button">Ausloggen</Link>
        </div>
    </div>
</template>

<script setup>
import {Link} from "@inertiajs/vue3";
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
//import router from '@/router';
//import CategoryAPI from '@/services/CategoryAPI';
import Category from './Category.vue';
//import UserAPI from '@/services/UserAPI';
import { usePage } from '@inertiajs/vue3'

//const props = defineProps(["categories"])

const page = usePage()
const categories = computed(() => page.props.sidebar.categories)

const store = useStore();
//const user = computed(() => store.getters['user/get']);
const loading = ref(false);
//const categories = computed(() => store.getters['categories/get']);
const selectedCategory = computed(() => store.getters['category/get']);
const showSidebar = ref(false);

/*const loadCategories = async () => {
    loading.value = true;
    const { status, data } = await CategoryAPI.getCategories();
    loading.value = false;
    if (status === 200) {
        store.dispatch('categories/set', data);
    }
};
if (!categories.value) loadCategories();*/

const resetSelectedCategory = () => {
    selectedCategory.value && store.dispatch('category/clear');
};

</script>

<style lang="sass">
.sidebar
    background: linear-gradient(var(--purple-dark), var(--purple-light))
    left: 0
    max-width: var(--sidebar-width)
    min-height: 100%
    position: absolute
    top: 0
    transition: transform 600ms
    width: 100%
    z-index: 5

    @media only screen and (max-width: 1024px)
        height: calc(100vh + 1px)
        margin-top: calc(var(--mobile-header-height) - 1px)
        min-height: 0
        position: fixed
        top: 0
        transform: translateX(calc(var(--sidebar-width) * -1))

    &--visible
        transform: translateX(0)

    &__logo
        align-items: center
        display: flex
        justify-content: center
        margin-top: 48px

        @media only screen and (max-width: 1024px)
            display: none

        img
            height: 96px
            user-select: none

    &__loading
        margin-top: 80px

        &--spinner
            animation: spin 1s linear infinite
            border: 4px solid var(--color-bg)
            border-radius: 9999px
            border-top-color: transparent
            height: 64px
            margin: 0 auto
            width: 64px

    &__category-list
        margin: 96px 0

        @media only screen and (max-width: 1024px)
            margin: 48px 0
            height: calc(100vh - var(--mobile-header-height) - 256px)
            overflow: scroll

    &__logout-button
        align-items: center
        background-color: #5d48ff
        border-radius: 26px
        color: white
        display: none
        font-size: 14px
        font-weight: bold
        height: 48px
        margin: 80px auto
        justify-content: center
        text-transform: uppercase
        width: 172px

        @media only screen and (max-width: 1024px)
            display: flex

.mobile-header
    align-items: center
    background-color: #5d48ff
    display: none
    height: var(--mobile-header-height)
    justify-content: center
    left: 0
    position: fixed
    top: 0
    width: 100%
    z-index: 6

    @media only screen and (max-width: 1024px)
        display: flex

    img
        height: auto
        width: 83px

    a
        display: flex

.mobile-header__button
    align-items: center
    display: flex
    flex-direction: column
    height: var(--mobile-header-height)
    justify-content: center
    left: 0
    position: absolute
    width: var(--mobile-header-height)

    &--active .btl-1
        transform: rotate(45deg)

    &--active .btl-2
        opacity: 0
        transform: scaleX(0)

    &--active .btl-3
        transform: rotate(-45deg)

.mobile-header__button-line
    background-color: #fff
    border-radius: 4px
    height: 4px
    margin: 0 0 5px
    transition: transform 600ms, opacity 400ms
    width: 27px

    &.btl-1
        transform-origin: top left

    &.btl-3
        transform-origin: bottom left


@keyframes spin
    to
        transform: rotate(360deg)
</style>
