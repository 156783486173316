<template>
  <Transition
    name="collapsible"
    @before-enter="onBeforeEnter"
    @enter="onEnter"
    @after-enter="onAfterEnter"
    @before-leave="onBeforeLeave"
    @leave="onLeave"
    @after-leave="onAfterLeave"
  >
    <slot></slot>
  </Transition>
</template>

<script>
export default {
  methods: {
    onBeforeEnter(el) {
      if (!el.style.height) {
        el.style.height = '0px';
      }
    },
    onEnter(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    onAfterEnter(el) {
      el.style.height = null;
    },
    onBeforeLeave(el) {
      if (!el.style.height) {
        el.style.height = `${el.offsetHeight}px`;
      }
    },
    onLeave(el) {
      el.style.height = '0px';
    },
    onAfterLeave(el) {
      el.style.height = null;
    },
  },
};
</script>

<style lang="sass">
.collapsible-enter-active,
.collapsible-leave-active
    transition: height 0.4s
</style>
